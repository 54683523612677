@media (max-width: 600px) {
  .subarea-text {
    display: none;
  }

  .subarea-img {
    flex: 0 0 100%;
  }

  .subarea-img-button {
    bottom: calc(
      30px + env(safe-area-inset-bottom)
    ); /* Ajuste para evitar la superposición con la barra de navegación */
  }

  .subarea-img p {
    bottom: 10px;
  }

  .avatar-component .subarea-img video,
  .subarea-img-full video {
    object-fit: cover;
  }

  .chat-container {
    background-color: transparent !important;
    border-radius: 0 !important;
    height: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    padding-top: 12%;
    box-shadow: none;
  }
  .messages {
    padding: 8px !important;
  }
}
