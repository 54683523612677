/* loading 3 dots */

.bot-thinking {
  text-align: center;
  color: #888888;
  font-style: italic;
  font-size: 28px;
}

.bot-thinking span {
  animation: blinking 1.5s infinite;
  opacity: 0.2;
}

.bot-thinking span:nth-child(1) {
  animation-delay: 0s;
}

.bot-thinking span:nth-child(2) {
  animation-delay: 0.5s;
}

.bot-thinking span:nth-child(3) {
  animation-delay: 1s;
}

@keyframes blinking {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

/* loading circle spinning */

.spinner {
  transform: translate(-50%, -50%);
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 7px solid #ccc;
  border-top-color: #007bff;
  animation: spin 1s linear infinite;
  margin: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
