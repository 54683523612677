.toast-container {
  display: none;
  position: fixed;
  top: 1em;
  right: 1em;
}

.toast-message {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 10px 20px;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
  width: 300px;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.toast-message.info {
  background-color: #2980b9;
  color: #fff;
}

.toast-message.success {
  background-color: #27ae60;
  color: #fff;
}

.toast-message.warn {
  background-color: #f39c12;
  color: #fff;
}

.toast-message.error {
  background-color: #c0392b;
  color: #fff;
}
