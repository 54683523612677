/* Variables para el tema claro (por defecto) */
:root {
  --background-color: #fff1e6;
  --text-color: #000; 
  --message-user-bg: #6666661f; /* fondo del mensaje del user */
  --message-bot-bg: #fff1e6; /* fondo del mensaje del bot */
  --message-time-color: #000; /* color del texto de la hora */
  --chat-container-bg: #fff1e6; /* color de fondo del chat-actions */
  --chat-actions-bg: #fff1e6; /* color de fondo del chat-actions */
  --bot-icon-url: url('../assets/images/CEU_Chat_negro.png');
  --gptceu-logo-url: url('../assets/images/GPT_CEU-logoNegro.png');
  --condiciones-url: #2980B9;
  --addFile-url: url('../assets/images/addFile-Negro.png')
}

/* Variables para el tema oscuro */
[data-theme="dark"] {
  --background-color: #011e2e; 
  --text-color: #fff;
  --message-user-bg: #2a3b44; /* fondo del mensaje del user */
  --message-bot-bg: #011e2e; /* fondo del mensaje del bot */
  --message-time-color: #fff; /* color del texto de la hora */
  --chat-container-bg: #011e2e; /* color del fondo del chat container */
  --chat-actions-bg: #011e2e; /* color de fondo del chat-actions */
  --bot-icon-url: url('../assets/images/CEU_Chat_blanco.png');
  --gptceu-logo-url: url('../assets/images/GPT_CEU-logoBlanco.png');
  --condiciones-url: #0fe6e6;
  --addFile-url: url('../assets/images/addFile-Blanco.png')
}

/* CHAT AND MESSAGES */
.chat-container {
  border-radius: 8px;
  margin: 10px;
  height: 85%;
  max-height: 85%;
  width: 65%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  background-color: var(--chat-container-bg);
  display: flex;
  flex-direction: column;
  position: relative;
}

.chat-image-container {
  width: 50%; /* Tamaño grande antes de enviar el mensaje */
  height: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: var(--gptceu-logo-url); /* Usa la variable CSS */
  transition: width 0.3s ease, height 0.3s ease; /* Transición suave */
}

/* Reducir tamaño de la imagen después de enviar el primer mensaje */
.chat-image-container.chat-image-after-first-message {
  width: 15%;
  height: 15%;
  margin-top: -30px;
}

.messages {
  gap: 7px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
  position: relative;
  height: 100%;
}

.message {
  border-radius: 6px;
  padding: 12px 25px;
  margin-bottom: 10px;
  font-family: 'Arial', sans-serif;
  max-width: 100%;
  word-wrap: break-word;
  position: relative;
  color: var(--text-color);
}

.message.user {
  background-color: var(--message-user-bg);
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  font-style: italic;
  padding-left: 15px;
}

.message.bot {
  background-color: var(--message-bot-bg);
  align-self: center;
  margin-left: 5px;
  width: 100%;
  box-shadow: none;  /* Eliminar cualquier sombra */
}

.message.bot:before {
  content: '';
  position: absolute;
  top: 2px;
  left: -13px;
  width: 35px;
  height: 35px;
  background-image: var(--bot-icon-url);
  background-size: contain;
  background-repeat: no-repeat;
}

.message-time {
  color: var(--message-time-color);
  margin-top: 5px;
  font-style: normal;
  font-size: 12px;
  text-align: right;
}

.message-type {
  font-size: 14px;
  font-weight: bolder;
  margin-right: 15px;
}

/* CAJA DEL CHAT */
.chat-actions,
.chat-actions-after-first-message {
  background-color: var(--chat-actions-bg);
  align-items: center; /* keep the horizontal align center */
  justify-content: center;
  padding: 5px 10px;
  width: 60%;
  height: auto;
  align-self: center;
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex; /* Ensure it's a flex container */
  flex-direction: column; /* Stack items vertically */
}

.chat-actions-after-first-message {
  width: 100%;
  position: static;
  margin-top: auto;
  transform: none;
}

/* CHAT INPUT */
.chat-input-container {
  display: flex;
  align-items: center; /* Alinea verticalmente el input y el icono */
  width: 100%;
  position: relative;
}

.chat-input {
  border-radius: 20px;
  border: 0;
  outline: 0;
  width: 100%;
  height: 45px;
  margin-right: 10px;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  padding: 7px 13px;
  margin: 5px 0;
  padding-right: 50px; /* Espacio para el icono */
}

.chat-input:focus {
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
}

.chat-icon {
  position: absolute;
  right: 10px; /* Posición a la derecha del input */
  cursor: pointer;
  width: 24px; /* Tamaño del icono */
  height: 24px;
  color: #011e2e; /* Color del icono */
}

.chat-icon:hover {
  color: var(--background-color); /* Cambia el color al hacer hover */
}

.chat-icon.disabled {
  color: #ccc; /* Color gris para indicar que está deshabilitado */
  cursor: default; /* Cambia el cursor para indicar que no se puede hacer clic */
}

.chat-icon:hover:not(.disabled) {
  color: #0056b3; /* Cambia el color al hacer hover, solo si no está deshabilitado */
}

/* MIC FROM CHAT */
.chat-mic-btn {
  border: 0;
  background: none;
  cursor: pointer;
}

/* UPLOAD FILE INPUT */
.upload-file-container {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  background-color: transparent;
}

.upload-file-label {
  cursor: pointer;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 10px;
  border: 0.5px solid var(--text-color);
  transition: opacity 0.3s linear;
}

.upload-file-label:hover {
  border: 1px solid #27ae60;
}

.file-container {
  background-color: var(--background-color);
  width: fit-content;
  padding: 5px 7px;
  border-radius: 5px;
  margin: 5px 0;
  border: 1px solid var(--text-color);
}

.upload-file-img {
  background-image: var(--addFile-url);
  background-size: contain;
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  margin-left: 5px;
}

.uploaded-files {
  margin-top: 10px;
  display: 'grid';
  grid-template-columns: repeat(3, 1fr);
  gap: '10px'
}

.file-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.file-name {
  font-size: 14px;
  color: var(--text-color);
}

/* UPLOAD FILE BUTTONS */
.file-btn {
  border: 0;
  padding: 6px 5px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
  transition: all 0.2s linear;
  width: 12.5%;
}

.file-cancel-btn {
  border: 1px solid transparent;
  background-color: #888;
  color: #fff;
}

.file-cancel-btn:hover {
  border: 1px solid red;
  background-color: transparent;
  color: var(--text-color);
}

.file-accept-btn {
  border: 1px solid transparent;
  color: #000;
  background-color: #fff;
}

.file-accept-btn:hover {
  border: 1px solid #27ae60;
  background-color: transparent;
  color: var(--text-color);
}

/* TAGS */
.tags-container {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.tag-container {
  margin-top: 10px; /* Space between input and tags */
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  width: 100%; /* Make tags span the full width */
}

.chat-tag {
  background-color: var(--message-user-bg);
  border: none;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  color: var(--text-color);
  transition: transform 0.2s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  justify-content: center;
}

.chat-tag:hover {
  background-color: rgba(0, 0, 0, 0.15); /* Slightly lighter hover effect */
  transform: scale(1.05); /* Subtle scale effect on hover */
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2); /* Soft shadow on hover */
}

.chat-icon.disabled {
  pointer-events: none;
}

/* SUGGESTIONS */
.suggestions-container {
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  padding: 5px;
}

.suggestion-item {
  padding: 8px 12px;
  cursor: pointer;
  color: #000;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.suggestion-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* CONDICIONES */
.condiciones {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.condiciones a {
  color: var(--condiciones-url);
  text-decoration: none;
}

.condiciones a:hover {
  text-decoration: underline;
}

.condiciones button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  display: block;
  margin: 20px auto;
  font-weight: bold;
}

.condiciones button:hover {
  border: 1px solid #27ae60;
  background-color: transparent;
  color: var(--text-color);
}

/* TABLAS */
.tabla {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: 8px;
  overflow: hidden;
}

/* Encabezado de la tabla */
.tabla th {
  background-color: var(--text-color);
  color: var(--chat-container-bg);
  padding: 12px;
  text-align: left;
  font-weight: bold;
  border-bottom: 2px solid var(--message-user-bg);
}

/* Contenido de la tabla */
.tabla tr {
  transition: background-color 0.3s ease;
}

.tabla tr:nth-child(even) {
  background-color: var(--message-user-bg);
}

/* Celdas de la tabla */
.tabla td {
  padding: 10px;
  border-bottom: 1px solid var(--chat-actions-bg);
}

.chart-container {
  margin: 0 auto;
  width: 80%;
  max-width: 600px;
  height: 400px;
  display: flex;
  justify-content: center; /* Centrado horizontal (lo mantienes) */
  align-items: center; /* Centrado vertical */
  
  /* Añade esto para posicionamiento absoluto si es necesario */
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}