/* Tema claro (por defecto) */
:root {
  --background-color: #fff1e6; /* Color para modo claro */
  --text-color: #000; /* Color de texto para modo claro */
  --profile-color-bg: #011e2e; /* Color de perfil de fondo */
  --text-profile-color: #fff; /* Color de texto de perfil */
  --footer-image-url: url('../assets/images/CEU-UCH_footer-negro.png');
  
  --logout-menu-bg: #fff;
  --text-color-logout: #000;
}

/* Tema oscuro */
[data-theme="dark"] {
  --background-color: #011e2e; /* Color para modo oscuro */
  --text-color: #fff; /* Color de texto para modo oscuro */
  --profile-color-bg: #fff1e6; /* Color de perfil de fondo*/
  --text-profile-color: #000; /* Color de texto de perfil */
  --footer-image-url: url('../assets/images/CEU-UCH_footer-blanco.png');
  
  --logout-menu-bg: #213844;  
  --text-color-logout: #fff;
}

/* Aplicar los colores a los elementos */
.avatar-component-des,
.subarea {
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Estilos adicionales (mantén los que ya tienes) */
.avatar-component {
  display: flex;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.avatar-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.subarea-img video,
.subarea-img-full video {
  width: 100%;
  height: 100%;
}

.subarea {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100%;
}

.subarea-img {
  flex: 0 0 50%;
  position: relative;
}

.subarea-img-full {
  flex: 0 0 100%;
  position: relative;
}

.subarea-text {
  flex: 0 0 50%;
}

.subarea-only-text {
  height: 100%;
  width: 100%;
}

.subarea-img-button {
  padding: 10px 20px;
  border: none;
  background-color: rgba(50, 107, 240, 0.7);
  color: white;
  transition: background-color 0.3s linear;
  position: absolute;
  bottom: calc(50px + env(safe-area-inset-bottom));
  z-index: 3;
  cursor: pointer;
  border-radius: 6px;
  width: fit-content;
  max-width: 500px;
  font-size: 14px;
}

.subarea-img p {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  bottom: 120px;
  left: 10px;
  z-index: 2;
}

.logout-btn img {
  width: 42px;
}

.logout-container {
  position: fixed; /* Fija el contenedor en la pantalla */
  top: 0; /* Lo coloca en la parte superior */
  left: 0; /* Lo coloca en el borde izquierdo */
  width: 100%; /* Ocupa el ancho completo de la pantalla */
  display: flex; /* Usa flexbox para alinear el contenido horizontalmente */
  justify-content: right; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  background-color: transparent; /* Color de fondo */
  z-index: 1000; /* Asegura que esté por encima de otros elementos */
  padding: 10px; /* Espaciado interno */
}

.logout-btn {
  background: none;
  border: none;
  cursor: pointer;
}
/*
.logout-btn:hover {

}*/

.logout-btn img{
  transition: transform 0.3s ease-in-out;
}

.logout-btn img:hover{
  transform: scale(1.25); /* Efecto de zoom sutil */
}

.logout-menu {
  position: absolute;
  top: 100%; /* Coloca el menú justo debajo del botón */
  right: 0;
  background-color: var(--logout-menu-bg);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  min-width: 160px;
  padding: 8px 0;
  overflow: hidden;
}

.logout-menu button {
  display: block;
  width: 100%;
  padding: 12px 16px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color-logout);
  font-size: 14px;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.logout-menu button:hover {
  background-color: #27ae60;
  transform: scale(1.02);
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Aplica la animación al menú cuando aparece */
.logout-menu {
  animation: fadeInDown 0.3s ease forwards;
}

/* Añadir separación entre los botones */
.logout-menu button:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--profile-color-bg);
  border: none;
  cursor: pointer;
}

.initials {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-profile-color);
}

.profile-photo {
  /*border-radius: 50%;*/
  border-radius: 50%;
  object-fit: scale-down;
  background-color: transparent; /* Asegúrate de que el fondo sea transparente */
}

.user-info {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.menu-profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.user-details {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  color: var(--text-color-logout);
}

.user-role {
  font-size: 12px;
  color: #999;
  margin: 0;
}

.initials.menu-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--profile-color-bg);
  color: var(--text-profile-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  margin-right: 12px;
}

.nuevo-chat-btn {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 16px;
  padding: 10px 16px;
  border-radius: 8px;
  color: var(--text-color);
  background-color: var(--background-color);
  border: 1px solid var(--text-color);
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.nuevo-chat-btn:hover {
  background-color: darken(var(--condiciones-url), 10%);
  color: var(--text-color);
  border: 1px solid #27ae60;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .nuevo-chat-btn {
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 12px;
    padding: 8px 12px; /* Reducir padding en pantallas pequeñas */
    font-size: 0.9rem;  /* Tamaño de fuente más pequeño */
    border-radius: 6px; /* Bordes ligeramente más pequeños */
  }
  .avatar-footer {
    display: none !important; /* Oculta el footer en pantallas pequeñas */
  }
}

/* Estilos para pantallas muy pequeñas (móviles muy compactos) */
@media (max-width: 480px) {
  .nuevo-chat-btn {
    padding: 6px 10px; /* Más pequeño aún */
    font-size: 0.8rem; /* Reducir aún más el tamaño de la fuente */
    margin-right: 8px;
  }
  .avatar-footer {
    display: none !important; /* Oculta el footer en pantallas muy pequeñas */
  }
}

/* Estilo del footer */
.footer-normal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
  z-index: 1000;
  /*background-color: red;*/
}

.footer-initial {
  position: absolute;
  bottom: 15%;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
  z-index: 1000;
  /*background-color: green;*/
}

.footer-image {
  max-width: 100px;
  height: auto;
  content: var(--footer-image-url); /* URL de la imagen */
}
