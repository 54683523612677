/* Scrollbar */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #929292 transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #929292;
  border-radius: 10px;
  border: 3px solid transparent;
}
