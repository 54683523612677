* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

@media (prefers-color-scheme: dark) {
  .body {
    background-color: #011e2e; /* Color para modo oscuro */
  }
}

@media (prefers-color-scheme: light) {
  .body {
    background-color: #ffe6c2; /* Color para modo claro */
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px; /* required for safari minimum size to avoid zoom when focus on input, selected or textarea */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input,
select:focus,
textarea {
  font-size: inherit; /* required for safari minimum size to avoid zoom when focus on input, selected or textarea */
}

.invisible {
  display: none;
}

.light-shadow {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
}

/* FLEX */

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.row-h-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.row-h-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row-v-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column-vh-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
