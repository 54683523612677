.not-found-container {
  height: 100%;
  padding: 0 10px;
}

.not-found-container img {
  width: 200px;
  opacity: 0.8;
}

.not-found-container p {
  text-align: center;
  max-width: 500px;
}
